<template>
    <div style="padding: 20px 20px 0 0;">
          <a-row style="padding: 0 20px;">
              <a-col :md="8" :sm="24" style="max-width: 400px;">
                <a-form-item label="拜访类型"  :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select  v-model="queryData.visitType" style="width: 100%" placeholder="请选择拜访类型">
                    <a-select-option :value="null" label="全部">全部</a-select-option>
                    <a-select-option :value="1" label="沟通记录">沟通记录</a-select-option>
                    <a-select-option :value="2" label="线下拜访">线下拜访</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" style="max-width: 400px;">
                <a-form-item label="拜访日期" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <!-- <a-range-picker :value="dateValue" :ranges="ranges" @change="dateChange"/> -->
                  <a-range-picker :value="dateValue" :ranges="ranges" :show-time="{ format: 'HH:mm' }" style="width: 100%"  format="YYYY-MM-DD HH:mm" :placeholder="['开始时间', '结束时间']" @change="dateChange"  />

                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" style="max-width: 400px;">
                <a-form-item label="客户经理" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.staffIdListParam" show-search mode="multiple"
                            :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                            style="width: 100%" placeholder="请选择客户经理"
                            :default-value="null" option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in users"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
           
              </a-row>
              <a-row style="padding: 0 20px;">
                <a-col :md="8" :sm="24" style="max-width: 400px;">
                <a-form-item label="客户名称" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.customerId" show-search
                            :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                            style="width: 100%" placeholder="请选择客户"
                            :default-value="null" option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in customerData"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
                <a-col :md="8" :sm="24" style="max-width: 400px;">
                  <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                    <a-range-picker :value="creDateValue" :ranges="ranges" @change="creDateChange"/>
                  </a-form-item>
                </a-col>

              <a-col :md="6" :sm="24">
                <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                          @click="getData">查询 
                </a-button>
              </a-col>
          </a-row>
            <a-modal :visible="isImgShow" @cancel="handleCancel" :footer="null" >
                <img :src="showimgurl" style="width: 100%"  />
            </a-modal>
        <div>
            <ul class="visit-ul" v-if="dataSource.length > 0" v-scroll-bottom="loadMore" ref="elementRef">
                <li v-for="item in dataSource" :key="item.id">
                  
                    <div style="display: flex;">
                        <p>
                            <span class="visit-label">拜访类型</span>
                            <span>{{ item.visitTypeName }}  <a-tag v-if="item.typeName">{{ item.typeName }}</a-tag></span>
                        </p>
                        <p style="margin-left: auto;font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 15px;color: #666666;">创建时间：{{ formatDate(item.createTime) }}</p>
                    </div>
                    <!-- <div style="display: flex;">
                        <p>
                            <span class="visit-label">拜访方式</span>
                            <span>{{ item.typeName }}</span>
                        </p>
                    </div> -->
                    <div style="display: flex;">
                        <p>
                            <span class="visit-label">客户经理</span>
                            <span>{{ item.staffsName }}</span>
                        </p>
                        <!-- <p style="margin-left: auto;font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 15px;color: #666666;">创建时间：{{ formatDate(item.createTime) }}</p> -->
                    </div>
                    <div>
                        <p>
                            <span class="visit-label">陪同拜访人</span>
                            <span v-if="item.accompanyStaffName">{{ item.accompanyStaffName }}</span>
                            <span v-if="!item.accompanyStaffName">-</span>
                        </p>
                    </div>
                    <div>
                        <p>
                            <span class="visit-label">客户名称</span>
                            <span>{{ item.customerName }}</span>
                        </p>
                    </div>
                    <div>
                        <p>
                            <span class="visit-label">概述</span>
                            <span>{{ item.content }}</span>
                        </p>
                    </div>
                    
                    
                    <div v-if="item.visitType == 2">   
                      <p>
                        <span class="visit-label">拜访人角色</span>
                        <a-tag color="#5542f6" v-if="item.role == 1">未知</a-tag>
                        <a-tag color="#5542f6" v-if="item.role == 2">总负责人</a-tag>
                        <a-tag color="#5542f6" v-if="item.role == 3">重要决策人</a-tag>
                        <a-tag color="#5542f6" v-if="item.role == 4">办事人员</a-tag>
                        <a-tag color="#5542f6" v-if="item.role == 5">其他</a-tag>
                      </p>
                    </div>
                    <div v-if="item.visitType == 2">
                        <p style="display: flex;cursor: pointer;" >
                            <span class="visit-label">现场照片</span>
                            <img @click="showModal(item.url)" style="width: 100px;height: auto;cursor: pointer;" :src="item.url" alt="">
                        </p>
                    </div>
                    <div>
                        <p>
                            <span class="visit-label">{{item.visitType == 1 ? '沟通时间' : '拜访时间'}}</span>
                            <span>{{ formatDate(item.visitTime) }}</span>
                        </p>
                    </div>
                </li>
                <div v-if="tabLoading" style="text-align: center;height: 60px;">加载中...</div>
                <div v-if="isListTips" style="text-align: center;height: 60px;line-height: 60px;">已加载完成全部数据</div>
                
            </ul>
            <a-empty style="margin-top: 100px;" v-if="dataSource.length == 0" />
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import {request} from '@/utils/request';

export default {
    props:{
        selectId:{
            type:String,
            default:"29eefb49-cd8c-475e-97f8-df39b383779d"
        },
        isEditRules:{
            type:Boolean,
            default:true
        }
    },
    data() {
        return {
            ranges:{'今天': [moment(), moment()],'昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],'当月': [moment().startOf('month'), moment().endOf('month')], '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]},
            queryData:{
                page:0,
                size:20,
                visitType:null
            },
            isImgShow:false,
            dataSource:[],
            moment,
            form:{},
            labelCol: {span: 8},
            wrapperCol: {span: 24},
            columns:[
                {title: '序号', customRender: (text, record, index) => index + 1, align: 'center',width:80},
                {title: '创建时间', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' },sorter: (a, b) => new Date(b.createTime) - new Date(a.createTime),
          defaultSortOrder: 'ascend'},
                {title: '拜访时间', dataIndex: 'visitTime', align: 'center', scopedSlots: { customRender: 'visitTime' }},
                { title: '陪同拜访人', dataIndex: 'accompanyStaffName', align: 'center',},
                {title: '拜访角色', dataIndex: 'role', align: 'center', scopedSlots: { customRender: 'role' }},
                {title: '拜访简述', dataIndex: 'content', align: 'center'},
                {title: '现场照片', dataIndex: 'url', align: 'center', scopedSlots: { customRender: 'url' }},
            ],
            rules: {
                visitTime: [{required: true, message: '请选择拜访日期', trigger: 'change'}],
                content: [{required: true, message: '请输入拜访简述', trigger: 'blur'}],
                role: [{required: true, message: '请选择拜访角色', trigger: 'change'}],
            },
            creDateValue:"",
            dateValue:"",
            showimgurl:"",
            users:null,
            customerData:null,
            tabLoading:false,
            hasMoreData:true,
            isListTips:false,
        }
    },
    mounted(){
        window.addEventListener('scroll', this.handleScroll);
         // 客户列表
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'get').then(res => {
        this.customerData = res.data.data
        })    //  客户经理
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post').then(res => {
        this.users = res.data.data;
        })
        this.getData()
    },
    directives: {
    'scroll-bottom': {
      bind(el, binding) {
        el.addEventListener('scroll', function() {
          if (el.scrollHeight - el.scrollTop === el.clientHeight) {
            binding.value();
          }
        });
      }
    }
  },
    methods:{
        handleScroll() {
            let scrollHeight = document.documentElement.scrollHeight;
            let clientHeight = document.documentElement.clientHeight;
            let scrollTop = document.documentElement.scrollTop;
            if (scrollHeight - clientHeight - scrollTop <= 100 && this.hasMoreData) {
            this.loadMore();
            }
        },
        computeElementHeight() {
            const element = this.$refs.elementRef;
            const rect = element.getBoundingClientRect();
            const distanceToTop = rect.top;
            this.computedHeight = window.innerHeight - distanceToTop;
      },
      loadMore() {
  if (this.tabLoading || !this.hasMoreData) return;
  this.tabLoading = true;
  request(process.env.VUE_APP_API_BASE_URL + 'sfa/visitrecord/list', 'post', this.queryData).then(res => {
    let data = res.data.data;
    this.tabLoading = false;
    if (res.data.code === 200) {
      if (data.data.length > 0) {
        let newData = new Set(data.data); // 使用 Set 来存储新加载的数据
        newData.forEach(item => {
          if (!this.dataSource.some(existingItem => existingItem.id === item.id)) {
            // 判断是否已经存在相同的数据，避免重复添加
            this.dataSource.push(item);
          }
        });
        this.queryData.page++; // 增加页码以请求下一页数据
        this.total = data.total;
      } else {
        this.hasMoreData = false; // 没有更多数据可供加载
        this.isListTips = true
      }
    } else {
      this.$message.error(res.data.message);
    }
  }).catch(error => {
    console.error('Error loading more data:', error);
  });
},
    showModal(text) {
        this.showimgurl=text;
        this.isImgShow = true;
    },
        handleCancel() {
            this.isImgShow = false;
    },
      dateChange(date) {
        this.dateValue = date;
        this.queryData.beginVisitTime = date[0]
        this.queryData.endVisitTime = date[1]
      },
      creDateChange(date, dateString) {
        this.creDateValue = date;
        this.queryData.beginCreateTime = dateString[0]
        this.queryData.endCreateTime = dateString[1]
      },
      
      formatDate(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        let result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return result;
       },
     reset(){
        this.queryData = {
                page:0,
                size:20,
                visitType:null
        }
        this.creDateValue = ''
        this.dateValue = ''
        this.getData()
      },
      getData() {
        this.tabLoading = true
        this.$message.loading()
        this.queryData.page = 0;
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/visitrecord/list', 'post',this.queryData).then(res => {
            this.tabLoading = false;
            // this.tableLoading = false;
            this.$message.destroy()
            if (res.data.code == 200) {
                this.dataSource = res.data.data.data
            } else {
                this.$message.error(res.data.message)
            }
       })
      }  
    }
}
</script>

<style>
.visit-ul {
    overflow-y: scroll;
    height: calc(100vh - 270px);
}
.visit-ul li{
    list-style: none;
    background: #FAFAFA;
    padding: 25px;
    box-sizing: border-box;
    color: #000000;
    font-size: 15px;
    margin-bottom: 16px;
}

.visit-label {
    display: inline-block;
    width: 100px;
    color: #666666;
}
</style>